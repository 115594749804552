import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import BoutonAcheterTirageAvecPrix from "../../../composants/boutique/BoutonAcheterTirageAvecPrix"
import Image from "../../../composants/image/Image"
import LayoutGalerieImageInteractive from "../../../composants/layout/LayoutGalerieImageInteractive"
import OpenSeaDragonViewer from "../../../composants/image/OpenSeaDragonViewer"

import {
  obtenirPage,
  obtenirImages,
  obtenirImage,
} from "../../../js/client-es/utils"

import fragmentsPages from "../../../js/client-es/fragments/fragments-pages"
import fragmentsImages from "../../../js/client-es/fragments/fragments-images"

export const requete = graphql`
  {
    page: allPagesCompilationYaml(filter: { code: { eq: "orionZoom" } }) {
      ...FragmentPageYaml
    }
    imageAVendre: allImagesCompilationYaml(
      filter: { code: { in: ["orion"] } }
    ) {
      nodes {
        ...FragmentDonneesImages
      }
    }
    imagesPaysage: allImagesCompilationYaml(
      filter: {
        code: {
          in: [
            "orionZoomAnnotee"
            "voieLacteeOrion"
            "haciendaDesEtoiles"
            "astroPixelProcessorOrion"
            "sgpOrion"
          ]
        }
      }
    ) {
      ...FragmentImagePaysage
    }
    imagesPaysageMoyen: allImagesCompilationYaml(
      filter: { code: { in: ["doubleSetup1"] } }
    ) {
      ...FragmentImagePaysageMoyen
    }
    imagesPortraitMoyen: allImagesCompilationYaml(
      filter: { code: { in: ["setupLaNuit"] } }
    ) {
      ...FragmentImagePortraitMoyen
    }
    imagesPortraitPetit: allImagesCompilationYaml(
      filter: { code: { in: ["doubleSetup2", "boutiqueCaisseAmericaine"] } }
    ) {
      ...FragmentImagePortraitPetit
    }
  }
`

export default function Orion360(props) {
  const resultatsRequete = useStaticQuery(requete)
  const page = obtenirPage(resultatsRequete, "page")
  const images = obtenirImages(
    resultatsRequete,
    "imageAVendre",
    "imagesPaysage",
    "imagesPaysageMoyen",
    "imagesPortraitMoyen",
    "imagesPortraitPetit"
  )

  const image = {
    xmlns: "http://schemas.microsoft.com/deepzoom/2009",
    Format: "jpg",
    Overlap: "1",
    Size: { Width: "18146", Height: "10643" },
    TileSize: "256",
    Url: "/ressources/deep-zoom/orion/tuiles/",
  }

  const composantImageInteractive = <OpenSeaDragonViewer image={image} />

  return (
    <LayoutGalerieImageInteractive
      page={page}
      propsPage={props}
      composantImageInteractive={composantImageInteractive}
      codePageRetour="galerieZoomsEt360Degres"
    >
      <section className="section-conteneur">
        <h2 className="section-titre">Avant-propos</h2>
        <p>
          Cette image de <strong>360 mégas pixels</strong>, 24 tuiles pour{" "}
          <strong>76 heures de poses</strong> couvre la presque intégralité de
          la constellation d’Orion, une des plus riches régions du ciel nocturne
          et distante d’environ 1500 années lumières de nous. Découvrez cette
          magnifique région du ciel, riche en étoiles dorées, nébuleuses sombres
          et colorées.
        </p>
        <p>Très belle promenade céleste.</p>
        <div id="galerie-image-interactive-conteneur-achat-tirage-avec-prix">
          <BoutonAcheterTirageAvecPrix
            image={obtenirImage(images, "orion")}
            langue={page.langue}
          />
        </div>
        <Image
          image={obtenirImage(images, "boutiqueCaisseAmericaine")}
          langue={page.langue}
          legende="Orion encadrée en caisse américaine et son auteur heureux !"
        />
      </section>
      <section className="section-conteneur">
        <h2 className="section-titre">Version annotée</h2>
        <p>
          27 objets ont été identifiés sur l’image ci-dessous. Cliquez dessus
          pour ouvrir une <strong>version zoomable annotée</strong> (en anglais)
          :
        </p>
        <Image
          image={obtenirImage(images, "orionZoomAnnotee")}
          langue={page.langue}
          afficherTitre={false}
        />
      </section>
      <section className="section-conteneur">
        <h2 className="section-titre">Situation</h2>
        <p>
          La constellation d’Orion se trouve dans le bras de la Voie Lactée du
          même nom dans lequel se trouve notre Système Solaire. Il se situe en
          périphérie de notre Voie Lactée.
        </p>
        <Image
          image={obtenirImage(images, "voieLacteeOrion")}
          langue={page.langue}
        />
      </section>
      <section className="section-conteneur">
        <h2 className="section-titre">La chasse au chasseur</h2>
        <p>
          Bien qu’elle soit visible depuis les deux hémisphères, la
          constellation d’Orion (le « chasseur »). est difficile à
          photographier. En Europe, elle est basse dans le ciel et n’est visible
          qu’en hiver lorsque le mauvais temps ne sévit pas. Les îles Canaries
          auraient pu être une destination intéressante pour tenter le cliché
          car la constellation y très haute dans le ciel, beaucoup plus qu’en
          Europe. Mais là-bas aussi, la météo est trop incertaine. Il restait
          donc l’hémisphère Sud. Mais hélas,{" "}
          <strong>
            l’été austral est souvent synonyme de saison des pluies
          </strong>{" "}
          dans les déserts facilement accessibles depuis la France.{" "}
        </p>
        <p>
          Après avoir surveillé deux hivers durant la météo mondiale,{" "}
          <strong>le sud de l’Atacama a été retenu :</strong> contrairement au
          nord de cette région et à la Namibie par exemple, les chances d’avoir
          des nuits claires à cette époque de l’année sont élevées. Nous sommes
          partis lors de la nouvelle Lune de décembre 2019 :{" "}
          <strong>
            à ce mois de l’année, Orion est au plus haut du début à la fin du
            crépuscule astronomique.
          </strong>{" "}
          Ce qui permettait de photographier la constellation 6 heures durant.
          En comparaison, les mois de novembre ou janvier ne laissent plus que 5
          heures par nuit… Nous avons donc fêté noël sous les étoiles…
        </p>
        <Image
          image={obtenirImage(images, "setupLaNuit")}
          langue={page.langue}
        />
      </section>
      <section className="section-conteneur">
        <h2 className="section-titre">L’hacienda des étoiles</h2>
        <p>
          Située dans la partie sud du désert de l’Atacama, nous avons été
          chaleureusement accueillis à l’Hacienda des étoiles, située à environ
          2 heures à l’est de la ville d’Ovalle.{" "}
          <strong>Un endroit complètement isolé,</strong> avec un ciel étoilé
          extraordinaire. Mais c’est surtout une véritable oasis de calme et de
          sérénité puisqu’aucune route n’y même : seul un chemin très défoncé et
          seulement praticable en 4x4 permet d’y accéder.
        </p>
        <Image
          image={obtenirImage(images, "haciendaDesEtoiles")}
          langue={page.langue}
          afficherTitre={false}
        />
      </section>
      <section className="section-conteneur">
        <h2 className="section-titre">Matériels</h2>
        <p>
          L’idée était de réussir le cliché en un voyage avec une bonne
          définition et un maximum de temps de pose pour{" "}
          <strong>un coût modéré : </strong>
        </p>
        <ul>
          <li>
            Afin de couvrir un maximum de champ par tuile,{" "}
            <strong>un capteur 24x36 mm</strong> s’imposait. Mon bon vieux EOS
            6D Mark I était donc tout désigné…
          </li>
          <li>
            En raison du peu de nuits disponibles sur place (11 nuits et risque
            réel de temps couvert) et pour maximiser le temps de pose total, je
            me suis équipé{" "}
            <strong>d’un second Canon 6d Mark I d’occasion</strong>. Il a été
            modifié Astrodon par la société EOS for Astro. Lors des prises de
            vue, un des deux boîtiers était piloté par ordinateur, tandis que
            l’autre était en mode « esclave », piloté avec un intervallomètre.
          </li>
          <li>
            Coté optiques, le choix s’est porté sur deux objectifs{" "}
            <strong>Canon 300mm F4 L non stabilisés</strong>, réputés en
            astrophotographie. Ils sont pourtant aujourd’hui boudés car ils sont
            vieux et non stabilisés… J’ai déniché les miens sur Ebay au Japon où
            j’ai trouvé des modèles en état absolument neuf âgés de presque 25
            ans (!).
          </li>
          <li>
            Pour éviter les aigrettes disgracieuses sur les étoiles brillantes,
            les deux objectifs{" "}
            <strong>étaient diaphragmés par l’avant à F5</strong> (voir mon
            livre électronique).
          </li>
          <li>
            Les poses unitaires étant de 3 minutes, de l’autoguidage était
            nécessaire malgré une monture équatoriale de qualité. Compte-tenu de
            l’échantillonnage assez large, un ZWO Mini Guider 30mm F4 et une
            Atik GP furent suffisants.
          </li>
        </ul>
        <div className="conteneur-cote-a-cote">
          <Image
            image={obtenirImage(images, "doubleSetup1")}
            langue={page.langue}
            afficherTitre={false}
          />
          <Image
            image={obtenirImage(images, "doubleSetup2")}
            langue={page.langue}
            afficherTitre={false}
          />
        </div>
      </section>
      <section className="section-conteneur">
        <h2 className="section-titre">Logiciels</h2>
        <p>
          La mosaïque a été programmée à l’aide du logiciel Sequence Generator
          Pro : lors des acquisitions,{" "}
          <strong>chaque tuile était cadrée informatiquement</strong> grâce une
          réduction astrométrique. L’assemblage final a ensuite été réalisé à
          l’aide du logiciel Astro Pixel Processor.
        </p>
        <Image
          image={obtenirImage(images, "astroPixelProcessorOrion")}
          langue={page.langue}
          afficherTitre={false}
        />
        <Image
          image={obtenirImage(images, "sgpOrion")}
          langue={page.langue}
          afficherTitre={false}
        />
      </section>
    </LayoutGalerieImageInteractive>
  )
}
